import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import React, { useEffect, useRef, useState } from "react";
import CustomerCard from "./CustomerCard";
import { toBeEnabled } from "@testing-library/jest-dom/matchers";

const NextArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <circle cx="21" cy="21" r="19" fill="#F0F0F0" />
    <path
      d="M22.3023 14.9689C22.6567 14.6145 23.208 14.6145 23.5623 14.9689C23.9167 15.3233 23.9167 15.8745 23.5623 16.2289L18.9555 20.9145L23.5623 25.6002C23.9167 25.9545 23.9167 26.5058 23.5623 26.8602C23.4048 27.0177 23.1686 27.0964 22.9323 27.0964C22.6961 27.0964 22.4598 27.0177 22.3023 26.8208L17.0655 21.5052C16.7111 21.1508 16.7111 20.5995 17.0655 20.2452L22.3023 14.9689Z"
      fill="#8C8C8C"
    />
  </svg>
);

const PrevArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <circle
      cx="19"
      cy="19"
      r="19"
      transform="matrix(-1 0 0 1 40 2)"
      fill="#F0F0F0"
    />
    <path
      d="M19.6977 14.9689C19.3433 14.6145 18.792 14.6145 18.4377 14.9689C18.0833 15.3233 18.0833 15.8745 18.4377 16.2289L23.0445 20.9145L18.4377 25.6002C18.0833 25.9545 18.0833 26.5058 18.4377 26.8602C18.5952 27.0177 18.8314 27.0964 19.0677 27.0964C19.3039 27.0964 19.5402 27.0177 19.6977 26.8208L24.9345 21.5052C25.2889 21.1508 25.2889 20.5995 24.9345 20.2452L19.6977 14.9689Z"
      fill="#8C8C8C"
    />
  </svg>
);

const CustomerCardSlider = ({ data, location }) => {
  const sliderRef = useRef();
  const [showPadding, setShowPadding] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPadding(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <article className="max-w-[2560px] mt-[-230px] sm:-mt-[250px] xl:mx-auto sm:px-0 px-5">
      <div>
        <Swiper
          style={{
            paddingTop: "20px",
            paddingBottom: "0",
          }}
          ref={sliderRef}
          className="slider"
          initialSlide={0}
          spaceBetween={20}
          centeredSlides={true}
          fadeEffect={true}
          lazyPreloaderClass="swiper-lazy-preloader"
          mousewheel={true}
          freeMode={true}
          navigation={{
            nextEl: ".custom-next-button",
            prevEl: ".custom-prev-button",
          }}
          lazyPreloadPrevNext={2.5}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 2.5,
            preloadImages: false,
            elementClass: "custom-lazy-class",
          }}
          slidesPerView={1}
          loop
          breakpoints={{
            2560: {
              slidesPerView: 4.5,
              spaceBetween: 20,
            },
            1920: {
              slidesPerView: 3.8,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 3.8,
              spaceBetween: 20,
            },

            640: {
              slidesPerView: 1.8,
              spaceBetween: 18,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          {!!data?.data?.allPrismicBlog?.edges.length &&
            data?.data?.allPrismicBlog?.edges.map((blog, index) => (
              <div className="swiper-wrapper">
                <SwiperSlide
                  key={`blog_card_${index}`}
                  style={{
                    maxWidth: "600px",
                    paddingRight: showPadding ? "20px" : "0px",
                    transition: "padding-right 0.5s ease-in-out",
                    paddingBottom: 0,
                  }}
                >
                  <CustomerCard
                    allowSubDetails={false}
                    searchText={""}
                    location={location}
                    key={`blog_card_${index}`}
                    blog={blog}
                  />
                </SwiperSlide>
              </div>
            ))}
        </Swiper>

        <section className="flex gap-9 sm:gap-11 justify-center items-center mt-5 md:mt-12 lg:mt-[30px]">
          <button
            className="custom-prev-button"
            onClick={() => {
              if (sliderRef.current && sliderRef.current.swiper) {
                sliderRef.current.swiper.slidePrev();
              }
            }}
          >
            <NextArrow />
          </button>
          <button
            className="custom-next-button"
            onClick={() => {
              if (sliderRef.current && sliderRef.current.swiper) {
                sliderRef.current.swiper.slideNext();
              }
            }}
          >
            <PrevArrow />
          </button>
        </section>
      </div>
    </article>
  );
};

export default CustomerCardSlider;
