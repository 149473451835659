import React from "react";

const CustomCardSkeleton = ({ length = 3 }) => {
  return (
    <section>
      {[...Array(length).keys()].map((index) => (
        <div
          key={index}
          className={` md:flex-row flex-col flex h-full  animate-pulse mt-5 width-full rounded-[18px]`}
        >
          <div className=" h-[182px] sm:h-[290px]   bg-gray-200 w-[100%] md:w-[40%] rounded-tl-[18px] md:rounded-bl-[18px] rounded-tr-[18px] md:rounded-tr-[0px]" />
          <div className="  bg-gray-200 w-[100%]  md:w-[60%]  animate-pulse flex gap-5 flex-col p-5  md:rounded-tr-[18px] rounded-br-[18px] rounded-bl-[18px] md:rounded-bl-[0px] ">
            <div className="h-10   bg-gray-300 w-[20%]" />
            <div className="h-10   bg-gray-300 w-[30%]" />
            <div className="h-10   bg-gray-300 w-[100%]" />
            <div className="h-10   bg-gray-300 w-[100%]" />
          </div>
        </div>
      ))}
    </section>
  );
};

export default CustomCardSkeleton;
